import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagApiResponse } from './sliceCbtTable';

export type QueryParamsRCBT = {
    page: number;
    limit: number;
    tahun: number;
    pemda: string[];
    kegiatan: number[];
    subkegiatan: number[];
    status: string;
    tagAwalHirarkiJenisIds: number[];
    tagAwalHirarkiSektorIds: number[];
    tagAwalHirarkiKategoriIds: number[];
    tagRekomendasiHirarkiJenisIds: number[];
    tagRekomendasiHirarkiSektorIds: number[];
    tagRekomendasiHirarkiKategoriIds: number[];
};

type User = {
    id: number;
    name: string;
    email: string;
};

export type TagHistory = {
    user: User;
    tag_sebelum: TagApiResponse[];
    tag_sesudah: TagApiResponse[];
    created_at: string;
};

export type RcbtType = {
  id: number;
  nama_pemda: string;
  nama_skpd: string;
  kegiatan: {
    kode: string;
    nama: string
  },
  subkegiatan: {
    kode: string;
    nama: string;
  },
  tag_awal: TagApiResponse[],
  tag_rekomendasi: TagApiResponse[],
  tag_realisasi: TagApiResponse[],
  tag_final: TagApiResponse[],
  tag_update_history: TagHistory[];
  status: string;
};

type Pagination = {
  total: number;
  per_page: number;
  current_page: number;
}

export type RcbtTableState = {
  pemda: boolean;
  kegiatan: boolean;
  subkegiatan: boolean;
  tag_awal: boolean;
  tag_rekomendasi: boolean;
  tingkat_aksi: boolean;
  status: boolean;
  get_again: number;
  data: RcbtType[];
  pagination: Pagination;
};

const initialState: RcbtTableState = {
  pemda: true,
  kegiatan: true,
  subkegiatan: true,
  tag_awal: true,
  tag_rekomendasi: true,
  tingkat_aksi: true,
  status: true,
  get_again: 0,
  data: [],
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
  },
};

const rcbtTableSlice = createSlice({
  name: 'rcbt_table',
  initialState,
  reducers: {
    setRcbtTable: (state, action: PayloadAction<Partial<RcbtTableState>>) => {
      return {
        ...state,
        ...action.payload,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    },
    clearRcbtTable: (state) => {
      return {
        ...initialState,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
  },
});

export const { setRcbtTable, clearRcbtTable } = rcbtTableSlice.actions;
export default rcbtTableSlice.reducer;